import { cn } from '@/utils';
import type { JSX } from 'react';
import React from 'react';

export type TextVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subheading1'
  | 'subheading2'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'caption'
  | 'overline1'
  | 'overline2';
  // | 'button1'
  // | 'button2'
  // | 'quoteSm'
  // | 'quoteMd'
  // | 'quoteLg'
  // | 'statistic'
  // | 'iconLabel';

export type TextProps = JSX.IntrinsicElements['span'] & {
  as?: keyof JSX.IntrinsicElements;
  variant: TextVariant;
};

function getElement(variant: TextVariant) {
  switch (variant) {
    case 'h1': return 'h1';
    case 'h2': return 'h2';
    case 'h3': return 'h3';
    case 'h4': return 'h4';
    case 'h5': return 'h5';
    case 'h6': return 'h6';
    case 'body1':
    case 'body2':
    case 'body3':
      return 'p';
    default: return 'span';
  }
}

export const Text = ({
  variant,
  as = undefined,
  className,
  ...props
}: TextProps) => {
  const Element = (as ?? getElement(variant)) as unknown as 'span';

  return (
    <Element
      {...props}
      className={cn(
        'typography',
        variant,
        className,
      )}
    />
  );
};
