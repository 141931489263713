import React, { useRef } from 'react';
import {
  List,
  Paper,
  Popper,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { useToggle } from '@react-shanties/core';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';

import NavMenuLink, { type NavMenuLinkDefinition } from '#components/NavMenuLink';
import type { ExpandedNavigationDefinition } from '#contentful/ExpandedNavigation';
import clsx from 'clsx';
import { slashySlug } from '../SmartLink';

export type NavMenuCategoryProps = NavMenuLinkDefinition & {
  contentfulId: string,
  subItems: NavMenuLinkDefinition[],
  // eslint-disable-next-line react/no-unused-prop-types
  expandedItem?: ExpandedNavigationDefinition,
};

const SmallMenu = ({
  label,
  slug,
  subItems,
  contentfulId,
}: NavMenuCategoryProps) => {
  const anchor = useRef(null);

  const [menuOpen, , { setOn, setOff }] = useToggle();
  const { language } = useI18next();

  const isContactCategory = contentfulId === '1cRvq5jA5pCenL84ClJpsh';

  return (
    <div
      ref={anchor}
      className={clsx(
        'group/nav-item',
        'm-0',
        isContactCategory && 'mt-[10px]',
        isContactCategory ? 'h-[70%]' : 'h-full',
      )}
      role="presentation"
      onMouseEnter={setOn}
      onMouseLeave={setOff}
    >
      <Link
        to={slashySlug(slug)}
        className="no-underline"
        language={language}
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <ListItemButton className={clsx(
          'h-full hover:bg-black/5',
          'flex items-center ',
          isContactCategory && 'border border-solid border-impblue300 rounded-[2rem] bg-lightblue-lightgreen-soft',
        )}
        >
          <ListItemText className={clsx(
            '*:text-base',
            isContactCategory ? 'text-impblue500' : 'text-impgray700',
          )}
          >
            {label}
          </ListItemText>
        </ListItemButton>
      </Link>

      {subItems && (
        <Popper
          anchorEl={anchor.current}
          open={menuOpen}
          placement="bottom-start"
          keepMounted
          onResize={undefined}
          onResizeCapture={undefined}
          className="z-[1000000]"
        >
          <Paper>
            <List disablePadding>
              {subItems.map(
                (subItem) => (
                  <NavMenuLink
                    key={subItem.id}
                    buttonClasses="w-[150px]"
                    label={subItem.label}
                    href={subItem.href}
                    slug={subItem.slug}
                  />
                ),
              )}
            </List>
          </Paper>
        </Popper>
      )}
    </div>
  );
};

export default SmallMenu;
