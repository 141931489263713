import React from 'react';

import type { CarouselDefinition } from '#components/Carousel';
import Carousel from '#components/Carousel';
import type { PdfDefinition } from '#components/Pdf';
import Pdf from '#components/Pdf';
import type { TabDefinition } from '#components/Tab';
import Tab from '#components/Tab';
import InteractiveExpertiseGraphic from '#components/InteractiveExpertiseGraphic';
import type { InteractiveExpertiseGraphicDefinition } from '#components/InteractiveExpertiseGraphic/InteractiveExpertiseGraphic';
import InteractiveMap from '#components/InteractiveMap';
import type { InteractiveMapDefinition } from '#components/InteractiveMap/InteractiveMap';
import IconList from '#components/IconList';
import type { IconListDefinition } from '#components/IconList/IconList';
import type { ImageCarouselDefinition } from '#components/ImageCarousel';
import ImageCarousel from '#components/ImageCarousel';
import Hero from './Hero';
import Text from './Text';
import Image from './Image';
import Quote from './Quote';
import Button from './Button';
import Video from './Video';
import Form from './Form';
import Accordion from './AccordionList';
import AutomatedSuggestedContent from './AutomatedSuggestedContent';
import ImageCollage from './ImageCollage';
import StatCard from './StatCard';
import Team from './Team';
import TextButton from './TextButton';
import Block from './Block';
import LocationCollection from './LocationCollection';
import PersonWithQuote from './PersonWithQuote';
import SuggestedContent from './SuggestedContent';
import ButtonGroup from './ButtonGroup';
import AnimatedText from './AnimatedText';
import CourseNextClass from './CourseNextClass';
import ContentfulLink from './Link';
import Steps from './Steps';
import TableContent from './TableContent';

import type { TeamDefinition } from './Team';
import type { HeroDefinition } from './Hero';
import type { TextDefinition } from './Text';
import type { ImageDefinition } from './Image';
import type { QuoteDefinition } from './Quote';
import type { ButtonDefinition } from './Button';
import type { VideoDefinition } from './Video';
import type { FormDefinition } from './Form';
import type { AccordionListDefinition } from './AccordionList';
import type { SuggestedContentDefinition } from './SuggestedContent';
import type { AutomatedSuggestedContentDefinition } from './AutomatedSuggestedContent';
import type { ImageCollageDefinition } from './ImageCollage';
import type { StatCardDefinition } from './StatCard';
import type { TextButtonDefinition } from './TextButton';
import type { BlockDefinition } from './Block';
import type { LocationCollectionDefinition } from './LocationCollection';
import type { PersonWithQuoteDefinition } from './PersonWithQuote';
import type { ButtonGroupDefinition } from './ButtonGroup';
import type { AnimatedTextDefinition } from './AnimatedText';
import type { SuggestedContentTabsDefinition } from './SuggestedContentTabs';
import SuggestedContentTabs from './SuggestedContentTabs';
import InteractiveGraphic from './InteractiveGraphic';
import type { InteractiveGraphicDefinition } from './InteractiveGraphic/InteractiveGraphic';
import type { CourseNextClassDefinition } from './CourseNextClass';
import type { ExpandableColumnsDefinition } from './ExpandableColumns';
import ExpandableColumns from './ExpandableColumns';
import type { LinkDefinition } from './Link';
import type { StepsDefinition } from './Steps';
import type { TableContentDefinition } from './TableContent';
import TextCard from './TextCard';
import type { TextCardContainerDefinition } from './TextCard/TextCard';

export type AnyContentfulComponentDefinition = (
  HeroDefinition
  | TextDefinition
  | ImageDefinition
  | QuoteDefinition
  | ButtonDefinition
  | VideoDefinition
  | FormDefinition
  | AccordionListDefinition
  | SuggestedContentDefinition
  | ImageCollageDefinition
  | StatCardDefinition
  | TeamDefinition
  | TextButtonDefinition
  | AutomatedSuggestedContentDefinition
  | BlockDefinition
  | LocationCollectionDefinition
  | PersonWithQuoteDefinition
  | CarouselDefinition
  | PdfDefinition
  | TabDefinition
  | InteractiveExpertiseGraphicDefinition
  | InteractiveMapDefinition
  | ButtonGroupDefinition
  | IconListDefinition
  | AnimatedTextDefinition
  | SuggestedContentTabsDefinition
  | InteractiveGraphicDefinition
  | ImageCarouselDefinition
  | CourseNextClassDefinition
  | ExpandableColumnsDefinition
  | LinkDefinition
  | StepsDefinition
  | TableContentDefinition
  | TextCardContainerDefinition
);

export type ContentfulComponentProps = {
  content: AnyContentfulComponentDefinition,
};

const ContentfulComponent = ({
  content,
}: ContentfulComponentProps) => {
  if (!content.internal?.type) {
    return null;
  }

  switch (content.internal?.type) {
    case 'ContentfulComponentHero':
      return <Hero key={content.id} content={content as HeroDefinition} />;
    case 'ContentfulComponentText':
      return (
        <Text
          key={content.id}
          content={content as TextDefinition}
          sx={{ px: { xs: 2, md: 0 } }}
        />
      );
    case 'ContentfulComponentImage':
      return <Image key={content.id} content={content as ImageDefinition} />;
    case 'ContentfulComponentQuote':
      return <Quote key={content.id} content={content as QuoteDefinition} />;
    case 'ContentfulComponentButton':
      return (
        <Button
          key={content.id}
          content={content as ButtonDefinition}
          sx={{ px: { xs: 2, md: 0 } }}
        />
      );
    case 'ContentfulComponentVideo':
      return (
        <Video
          key={content.id}
          content={content as VideoDefinition}
        />
      );
    case 'ContentfulComponentTeam':
      return (
        <Team
          key={content.id}
          content={content as TeamDefinition}
        />
      );
    case 'ContentfulComponentForm':
      return (
        <Form
          key={content.id}
          content={content as FormDefinition}
        />
      );
    case 'ContentfulComponentAccordion':
      return (
        <Accordion
          key={content.id}
          content={content as AccordionListDefinition}
        />
      );
    case 'ContentfulComponentSuggestedContent':
      return (
        <SuggestedContent
          key={content.id}
          content={content as SuggestedContentDefinition}
        />
      );
    case 'ContentfulComponentAutomatedSuggestedContent':
      return (
        <AutomatedSuggestedContent
          key={content.id}
          content={content as AutomatedSuggestedContentDefinition}
        />
      );
    case 'ContentfulComponentImageCollage':
      return (
        <ImageCollage
          key={content.id}
          content={content as ImageCollageDefinition}
        />
      );
    case 'ContentfulComponentStatisticsCard':
      return (
        <StatCard
          key={content.id}
          content={content as StatCardDefinition}
        />
      );
    case 'ContentfulComponentTextButton':
      return (
        <TextButton
          key={content.id}
          content={content as TextButtonDefinition}
          sx={{ px: { xs: 2, md: 0 } }}
        />
      );
    case 'ContentfulComponentBlock':
      return (
        <Block
          key={content.id}
          content={content as BlockDefinition}
        />
      );
    case 'ContentfulComponentLocationCollection':
      return (
        <LocationCollection
          key={content.id}
          content={content as LocationCollectionDefinition}
        />
      );
    case 'ContentfulComponentPersonWithQuote':
      return (
        <PersonWithQuote
          key={content.id}
          content={content as PersonWithQuoteDefinition}
        />
      );
    case 'ContentfulComponentCarousel':
      return (
        <Carousel
          key={content.id}
          {...content as CarouselDefinition}
        />
      );
    case 'ContentfulComponentPdf':
      return (
        <Pdf
          key={content.id}
          {...content as PdfDefinition}
        />
      );
    case 'ContentfulComponentTab':
      return (
        <Tab
          key={content.id}
          {...content as TabDefinition}
        />
      );
    case 'ContentfulComponentInteractiveMap':
      return (
        <InteractiveMap
          key={content.id}
          {...content as InteractiveMapDefinition}
        />
      );
    case 'ContentfulComponentInteractiveExpertiseGraphic':
      return (
        <InteractiveExpertiseGraphic
          key={content.id}
          {...content as InteractiveExpertiseGraphicDefinition}
        />
      );
    case 'ContentfulComponentButtonGroup':
      return (
        <ButtonGroup
          key={content.id}
          content={content as ButtonGroupDefinition}
        />
      );
    case 'ContentfulComponentIconList':
      return (
        <IconList
          key={content.id}
          {...content as IconListDefinition}
        />
      );
    case 'ContentfulComponentAnimatedText':
      return (
        <AnimatedText
          key={content.id}
          {...content as AnimatedTextDefinition}
        />
      );
    case 'ContentfulComponentSuggestedContentTabs':
      return (
        <SuggestedContentTabs
          key={content.id}
          content={content as SuggestedContentTabsDefinition}
        />
      );
    case 'ContentfulComponentInteractiveGraphic':
      return (
        <InteractiveGraphic
          key={content.id}
          {...content as InteractiveGraphicDefinition}
        />
      );
    case 'ContentfulComponentImageCarousel':
      return (
        <ImageCarousel
          key={content.id}
          {...content as ImageCarouselDefinition}
        />
      );
    case 'ContentfulComponentCourseNextClass':
      return (
        <CourseNextClass
          key={content.id}
          content={content as CourseNextClassDefinition}
        />
      );
    case 'ContentfulComponentExpandableColumns':
      return (
        <ExpandableColumns
          key={content.id}
          content={content as ExpandableColumnsDefinition}
        />
      );
    case 'ContentfulComponentLink':
      return (
        <ContentfulLink
          key={content.id}
          content={content as LinkDefinition}
        />
      );
    case 'ContentfulComponentSteps':
      return (
        <Steps
          key={content.id}
          content={content as StepsDefinition}
        />
      );
    case 'ContentfulComponentTableContent':
      return (
        <TableContent
          key={content.id}
          content={content as TableContentDefinition}
        />
      );
    case 'ContentfulComponentTextCardContainer':
      return (
        <TextCard
          key={content.id}
          content={content as TextCardContainerDefinition}
        />
      );
    default:
      console.error(`Unexpected component type: ${JSON.stringify(content)}`);
      return null;
  }
};

export default ContentfulComponent;
