import React, { useEffect, useRef, useState } from 'react';
import type { ContentfulComponentDefinition } from '#contentful/ContentfulComponentDefinition';
import { getMarginClass, getPaddingClass, type SpacingValue } from '#components/Layout/theme/spacing.tailwind';
import Column from '@/components/Column';
import type { TimeItemLabel, TimeLineItemProps } from './TimeLine/TimeLine';
import TimeLine from './TimeLine/TimeLine';
import type { TableContentItemDefinition } from './TableContentItem/TableContentItem';
import Text from '../Text';

export type TableContentDefinition = ContentfulComponentDefinition & {
  title: string,
  tableContentItemTitleColor: TableColor,
  tableContentItemOverlineColor: TableColor,
  tableContentItemDotFillColor: TableColor,
  tableContentItemDotHoverColor: TableColor,
  tableContentItemTimelineColor: TableColor,
  marginTop?: SpacingValue,
  marginLeft?: SpacingValue,
  marginRight?: SpacingValue,
  marginBottom?: SpacingValue,
  paddingTop?: SpacingValue,
  paddingLeft?: SpacingValue,
  paddingRight?: SpacingValue,
  paddingBottom?: SpacingValue,
  items: TableContentItemDefinition[],
  internal: {
    type: 'ContentfulComponentTableContent',
  },
};

type TableContentProps = {
  content: TableContentDefinition;
};

export type TableColor = {
  value: string,
};

const TableContent = ({ content }: TableContentProps) => {
  const {
    items,
    tableContentItemDotFillColor,
    tableContentItemDotHoverColor,
    tableContentItemOverlineColor,
    tableContentItemTimelineColor,
    tableContentItemTitleColor,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
  } = content;

  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const handleTimelineItemClick = (index: number) => {
    const section = sectionRefs.current[index];
    if (section) {
      const rect = section.getBoundingClientRect();
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const targetPosition = rect.top + scrollTop - 100;
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
      });
      setSelectedItem(index);
    }
  };

  const [timeLineContents, setTimeLineContents] = useState<TimeLineItemProps>({
    labels: [],
    dotFillColor: 'red',
    dotHoverColor: 'red',
    lineColor: 'red',
    overlineColor: 'red',
    titleColor: 'red',
    onItemClick: handleTimelineItemClick,
    selectedItem,
  });
  const [isTopFixed, setIsTopFixed] = useState(false);
  const [isBottomFixed, setIsBottomFixed] = useState(false);

  const handleScroll = () => {
    const contentElement = document.getElementById('content');
    const lastSection = sectionRefs.current[sectionRefs.current.length - 1];
    if (contentElement && lastSection) {
      const contentRect = contentElement.getBoundingClientRect();
      const lastSectionRect = lastSection.getBoundingClientRect();
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      if (contentRect.top <= 20 && lastSectionRect.bottom > window.innerHeight) {
        setIsTopFixed(true);
        setIsBottomFixed(false);
      } else if (lastSectionRect.bottom <= window.innerHeight) {
        setIsTopFixed(false);
        setIsBottomFixed(true);
      } else {
        setIsTopFixed(false);
        setIsBottomFixed(false);
      }

      const scrollPositionMiddle = scrollTop + window.innerHeight / 2;
      sectionRefs.current.forEach((section, index) => {
        if (section) {
          const rect = section.getBoundingClientRect();
          const sectionTop = rect.top + scrollTop;
          const sectionBottom = sectionTop + rect.height;

          if (scrollPositionMiddle > sectionTop && scrollPositionMiddle < sectionBottom) {
            setSelectedItem(index);
          }
        }
      });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const labels: TimeItemLabel[] = [];
    items?.forEach((element) => {
      labels.push({ textOverline: element.textOverline, titleContent: element.titleContent });
    });
    setTimeLineContents({
      labels,
      dotFillColor: tableContentItemDotFillColor.value,
      dotHoverColor: tableContentItemDotHoverColor.value,
      overlineColor: tableContentItemOverlineColor.value,
      lineColor: tableContentItemTimelineColor.value,
      titleColor: tableContentItemTitleColor.value,
      onItemClick: handleTimelineItemClick,
      selectedItem,
    });
  }, [items,
    tableContentItemDotFillColor,
    tableContentItemDotHoverColor,
    tableContentItemOverlineColor,
    tableContentItemTimelineColor,
    tableContentItemTitleColor,
    selectedItem]);

  return (
    <div className="flex">
      <div
        className={`hidden md:flex flex-wrap content-end w-[20%] ${getMarginClass({
          mt: marginTop, mb: marginBottom, ml: marginLeft, mr: marginRight,
        })}`}
      >
        <div
          id="timeline"
          className={`${getPaddingClass({
            pt: paddingTop, pb: paddingBottom, pl: paddingLeft, pr: paddingRight,
          })} ${isTopFixed ? 'fixed top-20 w-[10%]' : 'static'} ${isBottomFixed ? 'bottom-0' : 'bottom-[initial]'} ${isTopFixed || isBottomFixed ? 'h-auto' : 'h-full'} overflow-y-auto overflow-x-hidden bg-white flex`}
        >
          <TimeLine
            {...timeLineContents}
            selectedItem={selectedItem}
            onItemClick={handleTimelineItemClick}
          />
        </div>
      </div>
      <div id="content" className="w-[80%]">
        {items?.map((component, index) => (
          <div
            key={component.id}
            ref={(el: HTMLDivElement) => {
              sectionRefs.current[index] = el;
              return sectionRefs;
            }}
            className="p-4"
          >
            {
              component.introductionSection && (
                <Text content={component.introductionSection} />
              )
            }
            {component.component.map((item) => (
              <Column key={item.id} {...item} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TableContent;
