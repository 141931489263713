import type { MouseEvent } from 'react';
import React, { useCallback, useRef } from 'react';
import {
  Popper,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';

import { type NavMenuLinkDefinition } from '#components/NavMenuLink';
import type { ExpandedNavigationDefinition } from '#contentful/ExpandedNavigation';
import ExpandedNavigation from '#contentful/ExpandedNavigation';
import clsx from 'clsx';
import { slashySlug } from '../SmartLink';

export type NavMenuCategoryProps = NavMenuLinkDefinition & {
  contentfulId: string,
  expandedItem?: ExpandedNavigationDefinition,
  open: boolean,
  onOpen: (id: string) => void;
};

const LargeMenu = ({
  id,
  label,
  slug,
  contentfulId,
  expandedItem,
  open,
  onOpen,
}: NavMenuCategoryProps) => {
  const anchor = useRef(null);

  const { language } = useI18next();

  const isContactCategory = contentfulId === '1cRvq5jA5pCenL84ClJpsh';

  const toggle = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onOpen(open ? '' : id);
  }, [id, onOpen, open]);

  const close = useCallback(() => {
    onOpen('');
  }, [onOpen]);

  return (
    <div
      ref={anchor}
      className={clsx(
        'group/nav-item m-0',
        isContactCategory && 'mt-[10px]',
        isContactCategory ? 'h-[70%]' : 'h-full',
      )}
      role="presentation"
      onMouseLeave={close}
    >
      <Link
        to={slashySlug(slug)}
        className="no-underline"
        language={language}
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        onClick={toggle}
      >
        <ListItemButton className={clsx(
          'h-full hover:bg-black/5',
          'flex items-center ',
          isContactCategory && 'border border-solid border-impblue300 rounded-[2rem] bg-lightblue-lightgreen-soft',
        )}
        >
          <ListItemText className={clsx(
            '*:text-base',
            isContactCategory ? 'text-impblue500' : 'text-impgray700',
          )}
          >
            {label}
          </ListItemText>
          <ExpandMoreOutlinedIcon className={clsx('text-impgreen500', open && 'rotate-180')} />
        </ListItemButton>
      </Link>

      {expandedItem && (
        <Popper
          anchorEl={anchor.current}
          open={open}
          placement="bottom-start"
          keepMounted
          disablePortal
          onResize={undefined}
          onResizeCapture={undefined}
          className="z-[1000000] w-dvw box-border bg-transparent"
        >
          <div role="presentation">
            <ExpandedNavigation content={expandedItem} onLinkClick={close} />
          </div>
        </Popper>
      )}
    </div>
  );
};

export default LargeMenu;
