import React from 'react';

import type { LinkDefinition } from '#contentful/Link';
import type { RichTextContent } from '#components/RichText';
import type { ContentfulAlignment } from '#hooks/useContentfulAlignment';
import type { HeadingVariant } from '#components/BaseBlock';
import BaseBlock from '#components/BaseBlock';
import type { BackgroundDefinition } from '#components/Section';
import type { ContentfulComponentDefinition } from '../ContentfulComponentDefinition';
import type { ImageDefinition } from '../Image';

export type BlockDefinition = ContentfulComponentDefinition & {
  background?: BackgroundDefinition,
  overline?: string,
  image?: ImageDefinition,
  imagePlacement?: string,
  heading?: string,
  headingFontColor?: string,
  headingVariant?: HeadingVariant,
  subHeading?: string,
  subheadingFontColor?: string,
  info?: RichTextContent,
  richTextFontColor?: string,
  link?: LinkDefinition,
  alignment: ContentfulAlignment,
  marginTop?: string,
  marginBottom?: string,
  marginLeft?: string,
  marginRight?: string,
  paddingTop?: string,
  paddingBottom?: string,
  paddingLeft?: string,
  paddingRight?: string,
  rounding?: boolean,
  internal: {
    type: 'ContentfulComponentBlock'
  },
};

export type BlockProps = {
  content: BlockDefinition,
};

const Block = ({
  content: {
    background,
    alignment,
    overline,
    image,
    imagePlacement,
    heading,
    headingFontColor,
    headingVariant = 'h2',
    subHeading,
    subheadingFontColor,
    richTextFontColor,
    info,
    link,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    rounding,
  },
}: BlockProps) => (
  <BaseBlock
    background={background}
    alignment={alignment}
    image={image}
    imagePlacement={imagePlacement}
    overline={overline}
    heading={heading}
    headingFontColor={headingFontColor}
    headingVariant={headingVariant}
    subHeading={subHeading}
    subheadingFontColor={subheadingFontColor}
    info={info}
    richTextFontColor={richTextFontColor}
    ctaLabel={link?.label}
    href={link?.href}
    slug={link?.page?.slug}
    pt={paddingTop}
    pb={paddingBottom}
    pl={paddingLeft}
    pr={paddingRight}
    mt={marginTop}
    mb={marginBottom}
    ml={marginLeft}
    mr={marginRight}
    cornerRounding={rounding}
  />
);

export default Block;
