/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useEffect, useRef,
} from 'react';

import type { TextDefinition } from '#contentful/Text';
import type { LinkDefinition } from '#contentful/Link';
import type { AnySectionDefinition, BackgroundDefinition } from '#components/Section';
import { useTheme } from '@mui/material';
import Text from '#contentful/Text';
import Link from '#contentful/Link';
import Section from '#components/Section';
import type { ContentfulComponentDefinition } from '../ContentfulComponentDefinition';

export type ExpandedNavigationDefinition = ContentfulComponentDefinition & {
  title: TextDefinition,
  background: BackgroundDefinition,
  description: TextDefinition,
  links: LinkDefinition[],
  content: AnySectionDefinition,
  internal: {
    type: 'ContentfulSubComponentExpandedNavigation'
  }
};

export type ExpandedNavigationProps = {
  content: ExpandedNavigationDefinition;
  onLinkClick: () => void;
};

const ExpandedNavigation = ({
  content: {
    title,
    description,
    links,
    content,
    background,
  },
  onLinkClick,
  ...rest
}: ExpandedNavigationProps) => {
  const { palette } = useTheme();

  const myRef = useRef<HTMLDivElement>(null);

  // set links up to close this menu on click
  useEffect(() => {
    if (!myRef.current) {
      return () => {};
    }

    const linkElems = Array.from(myRef.current.getElementsByTagName('a'));

    // only way to add onClick behavior to gatsby links. Gatsby links don't accept an onClick event
    // *and* they do an e.stopPropagation() that prevents simply listening to
    // events that bubble up.
    linkElems.forEach((link) => link.addEventListener('click', onLinkClick));

    return () => {
      linkElems.forEach((link) => link.removeEventListener('click', onLinkClick));
    };
  }, [onLinkClick]);

  return (
    <div
      ref={myRef}
      style={{
        backgroundColor: palette?.background?.default,
        boxShadow: '2px 4px 8px rgba(0,0,0,0.2)',
      }}
      className="grid grid-cols-[1fr_2fr] mx-10"
      {...rest}
    >
      <div
        style={{
          background: background.color?.value,
        }}
        className="p-8"
      >
        <Text content={title} />
        <Text content={description} />
        {links.map(
          (link) => (
            <Link
              key={link.id}
              content={link}
            />
          ),
        )}
      </div>
      <div className="p-8">
        <Section section={content} />
      </div>
    </div>
  );
};

export default ExpandedNavigation;
