import React from 'react';

import { type NavMenuLinkDefinition } from '#components/NavMenuLink';
import type { ExpandedNavigationDefinition } from '#contentful/ExpandedNavigation';
import LargeMenu from './LargeMenu';
import SmallMenu from './SmallMenu';

export type NavMenuCategoryProps = NavMenuLinkDefinition & {
  contentfulId: string,
  subItems: NavMenuLinkDefinition[],
  expandedItem?: ExpandedNavigationDefinition,
  open: boolean,
  onOpen: (id: string) => void;
};

const NavMenuCategory = ({
  expandedItem,
  ...rest
}: NavMenuCategoryProps) => {
  if (expandedItem) {
    return <LargeMenu expandedItem={expandedItem} {...rest} />;
  }
  return <SmallMenu {...rest} />;
};

export default NavMenuCategory;
