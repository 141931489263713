import React, { useState } from 'react';
import {
  Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector,
  TimelineContent,
  timelineItemClasses,
} from '@mui/lab';
import { Grid, Typography } from '@mui/material';
import clsx from 'clsx';

export type TimeLineItemProps = {
  labels: TimeItemLabel[]
  dotFillColor: string,
  dotHoverColor: string,
  overlineColor: string,
  lineColor: string,
  titleColor: string,
  onItemClick: (index:number) => void,
  selectedItem: number | null,
};

export type TimeItemLabel = {
  textOverline?: string,
  titleContent: string,
};

const TimeLine = ({
  labels,
  dotFillColor,
  dotHoverColor,
  overlineColor,
  lineColor,
  titleColor,
  onItemClick,
  selectedItem,
}: TimeLineItemProps) => {
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);

  const handleMouseEnter = (title: string) => {
    setHoveredItem(title);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const handleHover = (originalColor: string, titleItem: string, section: number) => {
    if (section === selectedItem) {
      return dotHoverColor;
    }
    return hoveredItem === titleItem ? dotHoverColor : originalColor;
  };

  const lastItemIndex = labels.length - 1;

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
          height: 100,
        },
      }}
      placeholder={undefined}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
    >
      {
        labels.map((label, index) => (
          <TimelineItem
            key={label.titleContent}
            className="MuiTimelineItem-root"
            sx={{ cursor: 'pointer' }}
            onMouseEnter={() => handleMouseEnter(label.titleContent)}
            onMouseLeave={handleMouseLeave}
            onClick={() => onItemClick(index)}
          >
            {
              (lastItemIndex === index) ? (
                <TimelineDot
                  sx={{
                    bgcolor: handleHover(dotFillColor, label.titleContent, index),
                  }}
                  className="mt-1"
                />

              ) : (
                <TimelineSeparator>
                  <TimelineDot
                    sx={{
                      bgcolor: handleHover(dotFillColor, label.titleContent, index),
                    }}
                    className={clsx(index === 0 ? 'mt-3 mb-1' : 'mt-1 mb-1')}
                  />
                  {
                index <= labels.length && (
                  <TimelineConnector
                    sx={{
                      bgcolor: `${lineColor}`,
                      width: '2px',
                      height: '30px',
                    }}
                  />
                )
              }
                </TimelineSeparator>
              )
            }
            <TimelineContent className={clsx(index !== 0 && 'pt-0')}>
              <Grid container direction="column">
                <Grid>
                  <Typography
                    sx={
                    {
                      fontSize: '11px',
                      mt: index === 0 ? '5px' : '3px',
                      color: handleHover(overlineColor, label.titleContent, index),
                    }
}
                    variant="body2"
                  >
                    {label.textOverline}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      color: handleHover(titleColor, label.titleContent, index),
                      fontWeight: 'bold',
                    }}
                    variant="h6"
                  >
                    {label.titleContent}
                  </Typography>
                </Grid>
              </Grid>
            </TimelineContent>
          </TimelineItem>
        ))
      }
    </Timeline>
  );
};

export default TimeLine;
