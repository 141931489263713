import React from 'react';
import { Divider } from '@mui/material';
import { cn } from '@/utils';
import type { DividerProps as MuiDividerProps } from '@mui/material';

export type DividerProps = MuiDividerProps;

const ColumnDivider = ({ className, ...props }: DividerProps) => (
  <Divider
    orientation="vertical"
    flexItem
    className={cn(
      'bg-impgray900',
      'mr-[-1px]',
      className,
    )}
    {...props}
  />
);

export default ColumnDivider;
